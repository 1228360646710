// Color variables (appears count calculates by raw css)
@color0: #fdb913; // Appears 15 times
@color1: #1f1f1f; // Appears 5 times
@color2: #e8e8e8; // Appears 4 times
@color3: #333333; // Appears 3 times

// Width variables (appears count calculates by raw css)
@width1: 300px; // Appears 2 times

// Height variables (appears count calculates by raw css)
@height0: 50px; // Appears 4 times

.root-container {
  border-radius: 5px;
  box-shadow: 0px 0px 13px 2px rgba(5, 21, 63, 0.04);
}
.form-title {
  font-size: 24px;
  font-style: normal;
  font-weight: bold;
  line-height: 29px;
}
.title-container {
  padding: 23px 0px 22px 30px;
}
.form-container {
  margin: 30px 25px 0px 25px;
}
.project-type-container {
  display: flex;
  flex-direction: column;
}
.project-type {
  color: @color1;

  font-size: 22px;
  font-style: normal;
  font-weight: bold;
  line-height: 26px;
}

.job-container {
  margin-top: 30px;
}

.form-text {
  color: @color1;

  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 19px;
}
.form-input {
  border-radius: 5px !important;
  border: 1px solid @color2 !important;
  height: @height0;
}
.form-subtitle {
  color: @color1;

  font-size: 22px;
  font-style: normal;
  font-weight: bold;
  line-height: 26px;
}

.checkbox-text {
  align-items: center;
  color: @color3;
  display: flex;

  font-size: 12px;
  font-style: normal;
  font-weight: normal;
  letter-spacing: 0.055em;
  line-height: 14px;
}
.checkbox-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding-top: 20px;
}
.client-container {
  padding-bottom: 50px;
}
.client-title {
  color: @color1;

  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 19px;
  margin-bottom: 10px;
}
.save-button {
  background-color: @color0 !important;
  border-radius: 5px !important;
  border: 1px solid @color0 !important;
  color: @color1 !important;
  font-size: 15px !important;
  font-weight: bold;
  line-height: 18px !important;
  height: 58px !important;
  // width: 210px !important;
  padding-right: 40px !important;
  padding-left: 40px !important;
  margin-top: 30px;
}
.ant-form-item-label
  > label.ant-form-item-required:not(
    .ant-form-item-required-mark-optional
  )::before {
  content: none !important;
}
.ant-form-item-label > label::after {
  content: none !important ;
}

.ant-select-container {
  .ant-select-single {
    &:not(.ant-select-customize-input) {
      .ant-select-selector {
        align-items: center;
        border-radius: 5px !important;
        border: 1px solid @color2 !important;
        height: @height0 !important;
      }
    }
  }
}

.ant-select-arrow {
  color: @color0 !important;
}
.ant-select-selection-item {
  color: black;

  font-size: 14px;
}
.ant-select-selection-placeholder::after:hover {
  background-color: #fdb913 !important;
}

.date-container {
  .ant-picker {
    display: flex;
    height: @height0;
  }
}

.lead-date {
  border-radius: 5px;
  border: 1px solid @color2;

  border: 1px solid #e8e8e8 !important;
  border-radius: 5px !important;
}
.ant-picker-suffix {
  color: @color0 !important;
}
.estimate-date {
  border-radius: 5px !important;
  border: 1px solid @color2 !important;
  height: @height0;
}

.ant-checkbox-checked {
  .ant-checkbox-inner {
    background-color: @color0 !important;
    border-color: @color0 !important;
  }
}

.ant-checkbox-wrapper {
  &:hover {
    .ant-checkbox-inner {
      border-color: @color0 !important;
    }
  }
}
.ant-checkbox {
  &:hover {
    .ant-checkbox-inner {
      border-color: @color0 !important;
    }
  }
}
.ant-checkbox-input {
  &:focus + .ant-checkbox-inner {
    border-color: @color0 !important;
  }
}

.background-primary {
  background-color: @color0 !important;
}

.ant-radio-button-wrapper:not(:first-child)::before {
  width: 0 !important;
}

.cardFormContainer {
  box-shadow: 0px 0px 13px 2px rgba(5, 21, 63, 0.04);
  .ant-card {
    .ant-card-body {
      padding: 20px 29px;
      .insideContainer {
        .ant-select {
          width: 100%;
        }
      }
    }
  }
}
.estimate-container {
  .estimate-date {
    width: 100%;
  }
}

.radio-btn-container {
  margin-top: 23px;

  .ant-radio-group {
    margin-bottom: 25px;
  }
  .ant-radio-button-wrapper {
    text-align: center;
    padding-top: 15px;
    padding-bottom: 15px;
    border-radius: 10px !important;
    color: #969696 !important;
    border: 1px solid #d8d8d8 !important;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    height: auto;
    margin-right: 20px;
    width: 160px;
  }
  .ant-radio-button-wrapper.ant-radio-button-wrapper-checked {
    color: #000 !important;
  }
}

.radio-btn-container-2 {
  .ant-radio-button-wrapper {
    text-align: center;
    border-radius: 50px !important;
    border: 1px solid #d8d8d8 !important;
    color: #969696 !important;
    font-size: 16px;
    font-weight: 500;
    margin-right: 20px;
    width: 90px;
  }
  .ant-radio-button-wrapper.ant-radio-button-wrapper-checked {
    color: #000 !important;
  }
}
.cardFormContainer.card--without--padding {
  box-shadow: none;
  .ant-card {
    .ant-card-body {
      padding: 20px 0;
    }
  }
}

.createForm--state-dropdown  .ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector input{
  padding-top:8% !important;
}

.createForm--state-dropdown .ant-select-single.ant-select-show-arrow .ant-select-selection-item, .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder{
  padding-left:0.5% !important;
}

.estimate-date .rc-time-picker-meridian {
  text-transform: uppercase !important;
}
@primary-color: #FDB913;@link-color: #FDB913;