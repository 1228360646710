.sub-title {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  display: flex;
  align-items: center;
  letter-spacing: 0.055em;

  /* #969696 */

  color: #969696;
}

.notes-name-text {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;

  color: #1f1f1f;
}

.card-text {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  text-align: justify;

  /* #969696 */

  color: #969696;
}

.notes-card-container {
  padding-top: 10px;
}

.notes-card-root {
  background-color: white;
  width: 100%;
  padding-left: 20px;
  padding-right: 5px;
  margin: 0px;
}

@primary-color: #FDB913;@link-color: #FDB913;