.pre-checklist-root {
  background: #ffffff;
  box-shadow: 0px 0px 13px 2px rgba(5, 21, 63, 0.04);
  border-radius: 5px;
}

.ppc-title {
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
  padding: 23px 25px 22px 30px;
  color: #1f1f1f;
}

@primary-color: #FDB913;@link-color: #FDB913;