.sl-root {
  background: #ffffff;
  /* shadow 1 */

  box-shadow: 0px 0px 13px 2px rgba(5, 21, 63, 0.04);
  border-radius: 5px;
  .ant-checkbox-inner {
    border: 1px solid #969696;
  }
}

.sl-container {
  padding: 25px 30px 25px 30px;
}

.sl-title {
  font-weight: bold;
  font-size: 22px;
  line-height: 26px;

  color: #1f1f1f;
}

.sl-checkbox-container {
  margin-top: 26px;
}

.sl-checkbox-text {
  padding-left: 5px;
  margin-bottom: 20px;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;

  color: #333333;
}

@primary-color: #FDB913;@link-color: #FDB913;