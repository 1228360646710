.estimates-checklist-container {
  background: #ffffff;

  box-shadow: 0px 0px 13px 2px rgba(5, 21, 63, 0.04);
  border-radius: 5px;
}

.estimate-cheader {
  padding: 23px 0px 23px 30px;
}

.ec-title {
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;

  color: #1f1f1f;
}
.req-modal{
  h2{
font-weight: 400;
font-size: 16px;

color: #1F1F1F;
  }
}

.ec-checkbox-con {
  padding: 6px 0px 90px 30px;
}

.ec-checkbox-req{
  padding-left: 30px;
}

.es-checkbox-text {
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  margin-left: 15px;
  color: #333333;
  margin-top: 25px;
  margin-bottom: 0px;
}

.es-btn {
  margin-top: 40px;
  font-weight: bold !important;
  font-size: 15px !important;
  line-height: 18px !important;
  letter-spacing: 0.055em !important;
  height: 40px !important;
  width: 102px !important;
  color: #1f1f1f !important;
  // background: #fdb913 !important;
  border-radius: 5px !important;
}

.paint-pricing:hover{
  background-color: #fdb913 !important;
}
@primary-color: #FDB913;@link-color: #FDB913;