.contractor-payment-root {
  background: #ffffff;

  box-shadow: 0px 0px 13px 2px rgba(5, 21, 63, 0.04);
  border-radius: 5px;
}

.payment-table-content {
  p {
    font-weight: normal;
    font-size: 15px;
    line-height: 18px;

    color: #333333;
  }
}
.payment-record-title {
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;

  color: #1f1f1f;
}

.contractor-payment-title-padding {
  padding: 25px 30px;
}

@primary-color: #FDB913;@link-color: #FDB913;