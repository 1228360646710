.delivery-notes-root {
  background: #ffffff;
  box-shadow: 0px 0px 13px 2px rgba(5, 21, 63, 0.04);
  border-radius: 5px;
  padding: 30px;

  h1 {
    font-weight: bold;
    font-size: 22px;
    line-height: 26px;

    color: #1f1f1f;
  }
  p {
    font-weight: bold;
    font-size: 18px;
    // line-height: 20px;
    margin-top: 2px;
    color: #333333;
  }
}

@primary-color: #FDB913;@link-color: #FDB913;