.payment-tab-root {
  background: #ffffff;

  box-shadow: 0px 0px 13px 2px rgba(5, 21, 63, 0.04);
  border-radius: 5px;
}

.payment-tab-title {
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
  padding: 23px 20px 22px 30px;
  color: #1f1f1f;
}

.payment-tab-table {
  p {
    font-weight: normal;
    font-size: 15px;
    line-height: 18px;

    color: #333333;
  }
}

@primary-color: #FDB913;@link-color: #FDB913;