.deliver-date-root {
  background: #ffffff;
  height: 100%;
  border: 1px solid #e8e8e8;
  border-radius: 5px;
  h1 {
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    text-align: center;

    color: #333333;
  }
  p {
    font-weight: normal;
    font-size: 16px;
    line-height: 27px;
    margin: 0px;
    text-align: center;

    color: #333333;

    opacity: 0.8;
  }
}

.delivery-date-selector {
  width: 120px;
  height: 40px;
  border: 1px solid #e8e8e8;
  border-radius: 5px;
}

.ant-picker-time-panel-column::-webkit-scrollbar {
  width: 4px;
}

.ant-picker-time-panel-column::-webkit-scrollbar-thumb {
  background: #969696;
  border-radius: 5px;
}
.ant-picker-time-panel-column::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgba(150, 150, 150, 0.2);
  border-radius: 10px;
}

.pick-up-text {
  font-size: 16px !important;
}

.pick--up--button:hover{
  border: 1px solid #FDB913;
  border-left-color: #FDB913;
  border-left-width: 1px !important;
}

.delivery--button:hover{
  border: 1px solid #FDB913;
  border-left-width: 1px !important;
}

.ant-radio-button-wrapper:first-child{
  border-left: 1px solid #d9d9d9 !important;
}

.ant-radio-button-wrapper:first-child:hover{
  border-left: 1px solid #FDB913 !important;
}

@primary-color: #FDB913;@link-color: #FDB913;