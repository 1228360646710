@color0: #fdb913; // Appears 3 times

.ant-dropdown-trigger > .anticon.anticon-down {
  color: @color0;
  margin-right: 10px;
}
.ant-dropdown-link > .anticon.anticon-down {
  color: @color0;
  margin-right: 10px;
}
.ant-dropdown-button > .anticon.anticon-down {
  color: @color0;
  margin-right: 10px;
}
.ant-card-head {
  border-bottom: none;
  border-bottom: none !important;
}
.ant-card-body {
  padding-top: 5px !important;
}
.cardBox {
  border-radius: 5px;
  box-shadow: 0px 0px 13px 2px rgba(5, 21, 63, 0.04);
  min-height: 450px;
}
.insideContainer {
  .ant-select-single.ant-select-lg:not(.ant-select-customize-input)
    .ant-select-selector {
    height: 50px;
    border-radius: 5px;
    border: 1px solid #e8e8e8;
    align-items: center;
  }

  display: flex;
  flex-direction: column;
  margin-bottom: 25px;
}
.titleContent {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 10px;
}
.ant-dropdown-menu-item {
  &:hover {
    background-color: #fedc89 !important;
  }
}
.anticon {
  align-content: flex-end !important;
}
.assosicate--list {
  img {
    width: 75%;
  }
  h5 {
    font-size: 16px;
    color: #1f1f1f;
    padding-bottom: 0px;
    margin-bottom: 0;
    padding-top: 5px;
  }
  p {
    color: #969696;
    font-size: 12px;
  }
  .list--holder {
    margin-bottom: 20px;
  }
}

@primary-color: #FDB913;@link-color: #FDB913;