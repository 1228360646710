.ps-container {
  background: #ffffff;

  box-shadow: 0px 0px 13px 2px rgba(5, 21, 63, 0.04);
  border-radius: 5px;
}

.ps-title {
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
  padding: 23px 0px 24px 30px;
  color: #1f1f1f;
}

.ps-divider {
  border: 1px solid #e8e8e8;
}

.ps-sub-container {
  padding: 0px 55px;
}

.ps-client-container {
  padding-top: 90px;
  h1 {
    font-weight: bold;
    font-size: 22px;
    line-height: 26px;

    color: #1f1f1f;
  }
}

.ps-finalize-btn {
  font-weight: bold !important;
  font-size: 15px !important;
  line-height: 18px !important;

  letter-spacing: 0.055em !important;

  color: #1f1f1f !important;
  // background: #fdb913 !important;
  border-radius: 5px !important;
  height: 58px !important;
  width: 206px !important;
  margin-top: 60px;
}

@primary-color: #FDB913;@link-color: #FDB913;