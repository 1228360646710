.pay-processing-root {
  background: #ffffff;

  box-shadow: 0px 0px 13px 2px rgba(5, 21, 63, 0.04);
  border-radius: 5px;
  h1 {
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;

    color: #1f1f1f;
  }
}

.pp-add-associates {
  width: 175px !important;
  font-weight: 700 !important;
  font-size: 15px !important;
  line-height: 18px !important;

  letter-spacing: 0.055em !important;

  height: 40px !important;

  color: #1f1f1f !important;
  // background: #fdb913 !important;
  border-radius: 5px !important;
}

.pp-default-header {
  padding: 25px 30px;
}

.pp-default-table {
  margin-top: 20px;
  .ant-table-cell {
    padding-left: 25px;
  }
}

.pp-associates-menu {
  min-height: 30px !important;
  width: 175px !important;
}

.pp-associate-scroll-bar {
  overflow-y: scroll;
  overflow-x: hidden;
}
.pp-associate-scroll-bar::-webkit-scrollbar {
  width: 4px;
}

.pp-associate-scroll-bar::-webkit-scrollbar-thumb {
  background: #969696;

  border-radius: 5px;
}
.pp-associate-scroll-bar::-webkit-scrollbar-track {
  margin-top: 15px;
  box-shadow: inset 0 0 5px rgba(150, 150, 150, 0.2);
  border-radius: 10px;
}

.pp-associates-text {
  word-wrap: break-word;
  margin: auto;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height, or 126% */

  color: #333333;
}

@primary-color: #FDB913;@link-color: #FDB913;