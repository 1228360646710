/* Base style for ordered list */
ol,
ul {
  padding-left: 20px;
  margin: 0;
}

/* For indent level 1 */
.ql-indent-1 {
  margin-left: 20px;
  list-style-type: circle;
  /* Change sub-bullets to circle */
}

/* For indent level 2 */
.ql-indent-2 {
  margin-left: 40px;
  list-style-type: square;
  /* Example for further nesting */
}

/* Add more indent levels if needed */
.ql-indent-3 {
  margin-left: 60px;
  list-style-type: disc;
  /* Example for further nesting */
}
@primary-color: #FDB913;@link-color: #FDB913;