.admin-ec-root {
    background: #ffffff;
    /* shadow 1 */
  
    box-shadow: 0px 0px 13px 2px rgba(5, 21, 63, 0.04);
    border-radius: 5px;
  }
  
  .admin-ec-header {
    padding: 25px 30px;
    h1 {
      font-weight: 700;
      font-size: 24px;
      line-height: 29px;
  
      color: #1f1f1f;
    }
  }
  
  .admin-ec-add-single-btn {
    font-weight: 700 !important;
    font-size: 15px !important;
    line-height: 18px !important;
    height: 40px !important;
    letter-spacing: 0.055em !important;
    border-radius: 5px !important;
    // border: 1px solid #fdb913 !important ;
    // color: #fdb913 !important;
  }
  
  .admin-ec-add-btn {
    font-weight: 700 !important;
    font-size: 15px !important;
    line-height: 18px !important;
  
    height: 40px !important;
  
    letter-spacing: 0.055em !important;
  
    color: #1f1f1f !important;
    // background: #fdb913 !important;
    border-radius: 5px !important;
  }
  
  .hide-select-border {
    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
      border: none;
      border-radius: 0px;
    }
  }
  
  .paint-scroll-table {
    padding-bottom: 50px;
    .ant-table-container table > thead > tr:first-child th:last-child {
      background: #fedc89 !important;
    }
    .ant-table-container table > thead > tr:first-child th:first-child {
      background: #fedc89 !important;
    }
  }
  
@primary-color: #FDB913;@link-color: #FDB913;