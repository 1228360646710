.error-modal {
  width: 300px;
  p {
    color: #1f1f1f;
    font-size: 16px;
    margin-bottom: 0;
    font-weight: 500;
    margin: 0;
    padding: 0;
  }
}

.error-message {
}

@primary-color: #FDB913;@link-color: #FDB913;