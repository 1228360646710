.labor-container {
  background: #ffffff;
  /* shadow 1 */

  box-shadow: 0px 0px 13px 2px rgba(5, 21, 63, 0.04);
  border-radius: 5px;
}

.labor-header-title {
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
  /* identical to box height */

  /* Grey 1 */

  color: #1f1f1f;
}

.labor-header-container {
  padding: 25px 20px 22px 30px;
}

.add-surface {
  font-weight: bold;
  font-size: 15px !important;
  line-height: 18px !important;
  letter-spacing: 0.055em;
  border-radius: 5px !important;
  /* Grey 1 */
  height: 40px !important;

  color: #1f1f1f !important;
}

.labor-divider {
  border: 1px solid #e8e8e8;
  margin: 0;
}

.labor-rates-title {
  font-weight: bold;
  font-size: 22px;
  line-height: 26px;

  /* Grey 1 */

  color: #1f1f1f;
}

.labor-rates-container {
  padding-left: 20px;
  padding-bottom: 20px;
}

.option-detail-table-exp {
  .ant-select-arrow {
    display: none;
  }
}

.hide-select-border {
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border: none;
    background-color: inherit;
  }
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    padding: 0px 0px;
  }
}

.difficulty-select {
  margin-right: 3px !important;
}

.no-expand-icon .ant-table-row-expand-icon-cell {
  width: 0px !important;
  padding: 0 !important;
}

@primary-color: #FDB913;@link-color: #FDB913;