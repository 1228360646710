.check-request-root {
  background: #ffffff;

  box-shadow: 0px 0px 13px 2px rgba(5, 21, 63, 0.04);
  border-radius: 5px;
}

.check-request-title-header {
  padding: 25px 20px;
  h1 {
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;

    color: #1f1f1f;
  }
}

.check-request-add-new-btn {
  font-weight: 700 !important;
  font-size: 15px !important;
  line-height: 18px !important;
  height: 40px !important;
  letter-spacing: 0.055em !important;
  width: 130px !important;
  border-radius: 5px !important;
}

.cr-desc-card {
  border: 1px solid #e8e8e8;
  border-radius: 5px;
  padding: 20px;

  h1 {
    font-weight: 600;
    font-size: 16px;

    color: #1f1f1f;
  }

  p,
  input {
    font-size: 16px;

    color: #333333;

    opacity: 0.8;
  }
}

@primary-color: #FDB913;@link-color: #FDB913;