.rnt-card {
  background: #ffffff;
  box-shadow: 0px 0px 13px 2px rgba(5, 21, 63, 0.04);
  border-radius: 5px;
  padding: 20px;
  height: 200px;
  width: 400px;
  h1 {
    font-size: 20px;
  }
  h2 {
    font-size: 18px;
    cursor: pointer;
    color: #fdb913;
  }
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

@primary-color: #FDB913;@link-color: #FDB913;