.button-container {
  margin-bottom: 30px;
}

.highlight-top-border {
  .ant-table-cell {
    border-top: 1px solid black !important;
  }
}

.export-btn {
  // color: #fdb913 !important;
  // border: 1px solid #fdb913 !important;
  border-radius: 5px !important;
  font-size: 15px !important;
  height: 40px !important;
  width: 100px !important;
  font-weight: bold !important;
}

.notify-btn {
  // background-color: #fdb913 !important;
  // color: #1f1f1f !important;
  border-radius: 5px !important;
  font-size: 15px !important;
  font-weight: bold !important;
  margin-left: 10px !important;
  padding: 5px 30px !important;
  height: 40px !important;
  // border: 1px solid #fdb913 !important;
}
.edit-proposal-btn {
  // background-color: #fdb913 !important;
  color: #1f1f1f !important;
  border-radius: 5px !important;
  font-size: 15px !important;
  font-weight: bold !important;
  margin-left: 10px !important;
  padding: 5px 30px !important;
  height: 58px !important;
  // border: 1px solid #fdb913 !important;
}

.proposal-header {
  padding: 50px 25px 0px 30px;
}

.document-title {
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 43px;

  /* Grey 1 */

  color: #1f1f1f;
}

.certapro-text {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.055em;

  /* Grey 2 */

  color: #7f7f7f;
}

.header-text {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  text-align: right;
  color: #1f1f1f;
}
.header-proposal-type {
  font-style: normal;
  font-weight: normal;
  font-size: 28px;
  line-height: 32px;
  text-align: right;
  color: #1f1f1f;
}

.no-spacing {
  p {
    margin: 0px;
    padding: 3px;
  }
}

.content-title {
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 26px;
  margin-bottom: 0px;

  /* Grey 1 */

  color: #1f1f1f;
}

.jobsite-content-title {
  h1 {
    font-size: 16px;
    font-weight: 600;
    line-height: 19px;
    color: #1f1f1f;
  }
  p {
    white-space: pre-line;
    word-wrap: break-word;
    font-size: 16px;
    font-weight: normal;
    line-height: 20px;
    color: #333333;
    opacity: 0.8;
  }
}

.profile-content {
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;

  color: #333333;
}

.ant-descriptions-item-label {
  padding-bottom: none;
  margin-bottom: none;
}

.project-task-title {
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #333333;
  margin: 0;
}

// .terms-container {
//   .ant-descriptions-item-container .ant-descriptions-item-label,
//   .ant-descriptions-item-container .ant-descriptions-item-content {
//     display: block;
//   }
// }

.remove-padding {
  margin-top: 20px;
  .ant-descriptions-row > th,
  .ant-descriptions-row > td {
    padding-bottom: 0;
  }
}

.signature-container {
  hr {
    width: 218px;
    margin: 0px;
    border: 1px solid#1f1f1f;
    border-bottom: 0px;
  }
  h1 {
    margin: 0;
  }
}

.signature-italic {
  font-style: italic;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  /* identical to box height */

  text-transform: capitalize;

  /* Grey 1 */

  color: #1f1f1f;
}

.signature-title {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;

  color: #fdb913;
}

.terms-text {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;

  color: #333333;
}

.customer-text {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height, or 126% */

  /* Grey 2 */

  color: #333333;
}

.building-description {
  font-family: "lato";
  font-style: normal;
  color: #1f1f1f;
  white-space: pre-line;
  word-wrap: break-word;
  font-size: 15px;
  font-weight: normal;
  line-height: 18px;
}

.table-title {
  .ant-table-thead > tr > th {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    color: #333333;
  }
}

.tasks-wrapper {
  strong {
    margin-right: 3px;
  }
}

.customer-tasks {
  display: flex;
  flex-direction: row;
}

.proposal-tables-border-bottom {
  .ant-table-tbody > tr > td {
    border-bottom: 1px solid rgb(225, 225, 225);
  }
}

.option-table {
  .ant-table-container table > thead > tr:first-child th {
    background: #ffff !important;
  }
  .ant-table-row {
    line-height: 0px !important; /* Or whatever height you prefer */
  }
}

.option-table .ant-table {
  border-collapse: collapse; /* To ensure that borders are combined */
}

.option-table .ant-table-cell {
  border: 1px solid #000 !important;
  line-height: 0px; /* Apply the border to all table cells */
}

.option-table .ant-table-row {
  line-height: 0px; /* Or whatever height you prefer */
}

.bold-top-border {
  border-top: 2px solid black;
  font-weight: "bold";
}

.ql-size-huge {
  font-size: 2.5em;
}

.ql-size-large {
  font-size: 1.5em;
}

.ql-size-medium {
  font-size: 1em;
}

.ql-size-small {
  font-size: 0.75em;
}

@primary-color: #FDB913;@link-color: #FDB913;