.main-input-container {
  border: 1px solid #e8e8e8;
  border-radius: 5px;
  width: 100%;
  height: 150px;
  margin-top: 14px;
}

.ant-card-head {
  border-bottom: none !important;
}

.inputField {
  padding-top: 15px !important;
  padding-left: 15px !important;
  padding-bottom: 15px !important;
}

@primary-color: #FDB913;@link-color: #FDB913;