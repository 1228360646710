.ant-modal-close {
    color: #e2445c !important;
}

.req-model-container {
    .ant-modal-close {
        color: #e2445c !important;
    }
}

.send-request-card {
    width: 470px;
    background: #ffffff;
    border-radius: 6px;
}

.request-header-container {
    margin: 20px 30px 20px 20px;
}

.send-title-text {
    font-weight: bold;
    font-size: 22px;
    line-height: 26px;
    margin-top: 20px;
    color: #1f1f1f;
}

.title-divider {
    mix-blend-mode: normal;
    border: 1px solid #e8e8e8;
}

.send-req-date {
    background: #ffffff;
    /* Light Grey */
    height: 50px;
    border: 1px solid #e8e8e8 !important;
    border-radius: 5px !important;
    width: 100%;
}

.send-req-input-title {
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;

    color: #1f1f1f;
}

.remarks-field {
    background: #ffffff;
    height: 50px;

    border: 1px solid #e8e8e8 !important;
    border-radius: 5px !important;
}

.request-input-container {
    margin: 35px;
}

.cancel-btn {
    font-weight: bold;
    font-size: 15px !important;
    line-height: 18px;
    color: #fdb913 !important;
    letter-spacing: 0.055em;
    height: 40px !important;
    border: none !important;
    width: 100px;
}

.send-btn {
    margin: 0px 0px 0px 5px;
    font-weight: bold;
    font-size: 15px !important;
    line-height: 18px !important;
    height: 40px !important;
    letter-spacing: 0.055em;
    min-width: none;
    width: 160px;
    /* Grey 1 */

    color: #1f1f1f !important;
    border-radius: 5px !important;
}

.send-btn-container {
    margin: 10px 19px 10px;
}

.selected-checkbox {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fedc89;
    border-radius: 100px;
    margin: 5px;
}

.search-surface-input {
    height: 40px;
    background: #faf9f9 !important;
    border-radius: 5px;
    border: 1px solid #faf9f9 !important;
    margin-bottom: 17px;
}

.surface-checkbox-text {
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;

    /* Grey 2 */

    color: #333333;
}

.checkbox-tag-text {
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: #333333;
    padding: 8px;
    margin: 0px;
}

.surface-container {
    .ant-card-body {
        // padding-bottom: 0px !important;
        // padding-top: 10px !important;
        padding: 10px 10px 16px 10px !important;
    }

    .ant-list-bordered {
        border: none;
    }

    .ant-list-split .ant-list-item {
        border-bottom: none;
    }
}

.surface-list-container {
    padding: 2px 0px 2px 0px !important;
}

.checkbox-scroll-bar {
    overflow-y: scroll;
    overflow-x: hidden;
}

.notes-modal-container {
    .ant-modal-body {
        padding: 0px !important;
    }
}

.checkbox-scroll-bar::-webkit-scrollbar {
    width: 4px;
}

.checkbox-scroll-bar::-webkit-scrollbar-thumb {
    background: #969696;
    border-radius: 5px;
}

.checkbox-scroll-bar::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px rgba(150, 150, 150, 0.2);
    border-radius: 10px;
}

.surface-modal {
    width: 700px !important;
    height: 650px !important;
}

.show-more-btn {
    display: flex;
    justify-content: center;
    align-items: center;
}

.show-more-text {
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    text-decoration-line: underline;
    color: #fdb913;
    cursor: pointer;
}

.see-more-container {
    display: flex;
    align-items: flex-end;
}

.labor-rates-select-height {
    .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
        height: 50px;
        border: 1px solid #e8e8e8;
        border-radius: 5px;
        align-items: center;
    }
}

.edit-paint-modal {
    .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
        height: 50px !important;
        align-items: center;
        border-radius: 5px !important;
        border: 1px solid #e8e8e8 !important;
    }

    h1 {
        color: #1f1f1f;

        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 19px;
    }
}

.edit-paint-modal-input {
    border-radius: 5px !important;
    border: 1px solid #e8e8e8 !important;
    height: 50px;
}

// .ant-tabs-ink-bar {
//   display: none !important;
// }

// .ant-tabs-content-holder {
//   border-top: none;
// }
@primary-color: #FDB913;@link-color: #FDB913;