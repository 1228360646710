.job-type-comm-modal {
  .ant-select-single {
    &:not(.ant-select-customize-input) {
      .ant-select-selector {
        align-items: center;
        border-radius: 5px !important;
        border: 1px solid #e8e8e8 !important;
        height: 50px !important;
      }
    }
  }
  width: 450px !important;
  h1 {
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;

    color: #1f1f1f;
  }

  h2 {
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;

    color: #1f1f1f;
  }

  h3 {
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;

    color: #1f1f1f;
  }
}

.job-type-add-comm-btn {
  font-weight: 700 !important;
  font-size: 15px !important;
  line-height: 18px !important;
  height: 40px !important;
  letter-spacing: 0.055em !important;
  // background: #fdb913 !important;
  border-radius: 5px !important;
  color: #1f1f1f !important;
}

.job-type-add-comm-cancel {
  height: 40px !important;
  font-weight: 700 !important;
  font-size: 15px !important;
  line-height: 18px !important;
  letter-spacing: 0.055em !important;
  border: none !important;
  // color: #fdb913 !important;
}

@primary-color: #FDB913;@link-color: #FDB913;