.option-details-header {
  margin: 20px 23px 23px 30px;
}

.options-divider {
  border: 1px solid #e8e8e8;
}

.option-details-title {
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;

  color: #1f1f1f;
}

.jump-option-btn {
  border-radius: 5px !important;
  font-weight: bold !important;
  font-size: 15px !important;
  line-height: 18px !important;
  width: 175px;
  letter-spacing: 0.055em;
  border: 1px solid #fdb913 !important;
  color: #fdb913 !important;
}

.details-sub {
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;

  color: #1f1f1f;
}

.details-align {
  display: flex;
}

.details-container {
  padding: 35px 30px 0px 30px;
}

.group-notes-container {
  margin-top: 50px;
}

.option-details-sub {
  font-weight: bold;
  font-size: 22px;
  line-height: 26px;

  color: #1f1f1f;
}

.details-sub-container {
  .ant-select-single:not(.ant-select-customize-input) {
    .ant-select-selector {
      height: 38px !important;
    }
  }
  h2 {
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: #1f1f1f;
  }
  p {
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;

    color: #333333;

    opacity: 0.8;
  }
}

.building-dropdown {
  width: 81px;

  border: 1px solid #e8e8e8;
  border-radius: 5px;
}

.group-notes-btn {
  width: 130px;
  font-weight: bold;
  font-size: 15px !important;
  line-height: 18px;
  letter-spacing: 0.055em;
  // color: #fdb913 !important;
  // border: 1px solid #fdb913 !important;
  border-radius: 5px !important;
}

.gn-address-title {
  margin-top: 20px;
  margin-bottom: 15px;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;

  color: #1f1f1f;
}

.gn-address {
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height, or 126% */

  text-align: justify;

  /* #969696 */

  color: #969696;
}

.od-margin-bottom {
  margin-bottom: 16px;
}

.od-margin-top {
  margin-top: 30px;
}

.wash-cb {
  margin-left: 10px;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #333333;
}

.wash-sq-ft {
  margin-top: 10px;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #333333;
}

.buildings-scroll-bar {
  overflow-y: scroll;
  overflow-x: hidden;
}

.buildings-search {
  background: #faf9f9 !important;
  border: 1px solid #e8e8e8 !important;
  margin-bottom: 10px;
  border-radius: 5px !important;
}

.buildings-menu {
  height: 230px !important;
  width: 175px !important;
}
.option-input-container {
  padding: 10px 10px 0px 10px !important;
}

.buildings-scroll-bar::-webkit-scrollbar {
  width: 4px;
}

.buildings-scroll-bar::-webkit-scrollbar-thumb {
  background: #969696;
  border-radius: 5px;
}
.buildings-scroll-bar::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgba(150, 150, 150, 0.2);
  border-radius: 10px;
}

.view-pjcc-btn {
  position: absolute !important;
  bottom: 50%;
  font-weight: bold !important;

  font-size: 15px !important;
  line-height: 18px !important;

  letter-spacing: 0.055em !important;
  width: 165px !important;
  height: 40px !important;
  color: #1f1f1f !important;
}

.invert-pjcc-btns {
  transform: rotate(270deg);
  display: flex;
  align-items: center;
}

.pjcc-show-icon {
  margin-right: 10px;
  transform: rotate(270deg);
}

.jmp-search-text {
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  display: table-cell !important;
  vertical-align: middle !important;
  display: flex;
  align-items: center;

  color: #333333;
}
.table--editor--holder {
  .text--with--border {
    // border: 1px solid #e8e8e8;
    width: 120px;
    padding: 3px;
    border-radius: 5px;
    padding-left: 10px;
    font-size: 16px;
  }
  .minus--icon--holder {
    background-color: #fdb913;
    padding: 0px;
    width: 30px;
    height: 30px;
    cursor: pointer;
    line-height: 26px;
    font-size: 20px;
    text-align: center;
    border-radius: 50%;
    margin-left: 6px;
  }
  .qty--icon--holder {
    background-color: #fdb913;
    padding: 0px;
    width: 30px;
    height: 30px;
    cursor: pointer;
    line-height: 26px;
    font-size: 15px;
    text-align: center;
    border-radius: 2px;
    margin-left: 6px;
    color: #333333;
  }
  .qty--text--field {
    border: 1px solid #fdb913;
    padding: 0px;
    width: 65px;
    background-color: #fff;
    height: 30px;
    cursor: pointer;
    line-height: 26px;
    font-size: 15px;
    text-align: center;
    text-align: center;
    border-radius: 2px;
    margin-left: 8px;
    color: #333333;
    box-shadow: none;
    input {
      border: none;
      text-align: center;
    }
  }
  .text--with--noborder {
    border: none;
    color: #333;
    font-size: 16px;
  }
  .qty--text--field--gray {
    border: 1px solid #333;
    padding: 0px;

    width: 50px;
    background-color: #fff;
    height: 35px;
    cursor: pointer;
    line-height: 35px;
    font-size: 15px;
    text-align: center;
    text-align: center;
    border-radius: 2px;
    margin-left: 0px;
    color: #333333;
    box-shadow: none;
    input {
      border: none;
      text-align: center;
    }
  }
}
.table-expanded--holder {
  padding: 0 50px 0 80px;
  .bar-full {
    width: 20px;
    height: 8px;
    border-radius: 2px;
    margin-right: 2px;
    background: #00b44f;
    &:hover {
      opacity: 0.3;
    }
  }
  .bar-half {
    width: 20px;
    border-radius: 2px;
    height: 8px;
    margin-right: 2px;
    background: #00b44f;
    opacity: 0.3;
    &:hover {
      opacity: 1;
    }
  }
}
.table-form-group {
  span {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 10px;
    display: block;
  }
  input {
    width: 103px;
    height: 38px;
    display: block;
  }
}

.table-row-material {
  h1 {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 10px;
    display: block;
  }
}
.option-expandable-comp {
  .ant-rate-star {
    opacity: 1;
  }
}

.set-opacity {
  .ant-rate-star > div {
    transition: none;
  }
  .ant-rate-star:not(:last-child) {
    margin-right: 0px;
  }

  .ant-rate-star-zero {
    .ant-rate-star-second {
      color: #d1cec4 !important;
    }
  }
}


.misc--table .ant-table-thead > tr > th, .ant-table-tbody > tr > td, .ant-table tfoot > tr > th, .ant-table tfoot > tr > td{
  overflow-wrap: anywhere !important;
}
@primary-color: #FDB913;@link-color: #FDB913;