.psc-container {
  h1 {
    font-weight: bold;
    font-size: 22px;
    line-height: 26px;
    padding-bottom: 25px;
    color: #1f1f1f;
  }

  h2 {
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;

    color: #1f1f1f;
  }

  h3 {
    font-size: 16px;
    line-height: 20px;

    color: #333333;

    opacity: 0.8;
  }
}

.cc-container {
  margin-top: 30px;
  padding-bottom: 20px;
  h4 {
    font-weight: bold;
    font-size: 22px;
    line-height: 26px;

    color: #969696;
  }
  p {
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;

    color: #333333;
  }
}

.subtitle-margin {
  margin-top: 10px;
}

.ps-summary-container {
  margin-top: 60px;
  padding-bottom: 30px;
  h1 {
    font-weight: bold;
    font-size: 22px;
    line-height: 26px;

    color: #1f1f1f;
  }
  p {
    padding-top: 5px;
    font-weight: normal;
    font-size: 16px;
    line-height: 25px;

    color: #1f1f1f;
  }
}

.p-setup-container {
  margin-top: 30px;
  h1 {
    font-weight: bold;
    font-size: 22px;
    line-height: 26px;

    color: #1f1f1f;
  }
  h2 {
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;

    color: #1f1f1f;
  }
  p {
    padding-left: 10px;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;

    color: #333333;
  }
}

.p-setup-sub {
  margin-top: 25px;
}

.setup-ch-title {
  margin-bottom: 20px;
}

.surface-ch-container {
  padding-top: 60px;

  h1 {
    font-weight: bold;
    font-size: 22px;
    line-height: 26px;

    color: #1f1f1f;
  }

  p {
    padding-left: 10px;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;

    color: #333333;
  }
}

.s-ch-sub {
  margin-top: 20px;
  p {
    strong {
      margin-right: 5px;
    }
  }
}

.ql-picker.ql-font {
  .ql-picker-item {
    font-size: 0;
    &:before {
      content: attr(data-value) !important;
      font-size: 14px;
    }
  }
}

.ql-picker.ql-font {
  .ql-active {
    &:before {
      content: attr(data-value) !important;
      font-size: 14px;
    }
  }
}

.ql-picker.ql-font .ql-picker-label[data-value="Roboto"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="Roboto"]::before {
  font-family: "Roboto", cursive;
  content: "Roboto" !important;
}

.ql-picker.ql-font .ql-picker-label[data-value="Raleway"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="Raleway"]::before {
  font-family: "Raleway", cursive;
  content: "Raleway" !important;
}

.ql-picker.ql-font .ql-picker-label[data-value="Montserrat"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="Montserrat"]::before {
  font-family: "Montserrat", cursive;
  content: "Montserrat" !important;
}

.ql-picker.ql-font .ql-picker-label[data-value="Lato"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="Lato"]::before {
  font-family: "Lato", cursive;
  content: "Lato" !important;
}

.ql-picker.ql-font .ql-picker-label[data-value="Rubik"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="Rubik"]::before {
  font-family: "Rubik", cursive;
  content: "Rubik" !important;
}

/* Set content font-families */
.ql-font-Roboto {
  font-family: "Roboto";
}
.ql-font-Raleway {
  font-family: "Raleway";
}
.ql-font-Montserrat {
  font-family: "Montserrat";
}
.ql-font-Lato {
  font-family: "Lato";
}
.ql-font-Rubik {
  font-family: "Rubik";
}

@primary-color: #FDB913;@link-color: #FDB913;