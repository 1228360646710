.onsite-container {
  background: #ffffff;

  box-shadow: 0px 0px 13px 2px rgba(5, 21, 63, 0.04);
  border-radius: 5px;
}

.checklist-title {
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
  margin: 0;
  color: #1f1f1f;
}

.checklist-title-container {
  padding: 23px 30px 22px 30px;
}

.checklist-divider {
  border: 1px solid #e8e8e8;
}

.checklist-subtitle {
  font-weight: bold;
  font-size: 22px;
  line-height: 26px;

  color: #000000;
}

.source-container {
  margin: 35px 30px 20px 30px;
  padding-bottom: 10px;
  h3 {
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;

    color: #1f1f1f;
  }
}

.location-input {
  height: 50px;
  background: #ffffff !important;
  border-radius: 5px !important;
}

.choose-btn {
  color: #fdb913 !important;
  border: 1px solid #fdb913 !important;
  border-radius: 5px !important;
}

.no-file-text {
  padding-left: 15px;

  font-weight: normal;
  font-size: 12px;
  line-height: 14px;

  letter-spacing: 0.055em;

  color: #969696;
}

// .upload-photo-container {
//   // border: 1px solid #e8e8e8;
//   // border-radius: 5px;
//   padding-left: 5px;
//   // height: 50px;
// }
// .onsite-container {
//   .ant-upload-list {
//     display: inline-block;
//     // vertical-align: bottom;
//     width: 60%;
//     // margin-left: 9px;
//     .ant-upload-list-item {
//       height: 30px;
//     }
//   }
//   .ant-upload.ant-upload-select {
//     vertical-align: top;
//     // margin-top: 8px;
//   }
// }

.customize-select-height {
  .ant-select-single.ant-select-lg:not(.ant-select-customize-input)
    .ant-select-selector {
    height: 50px;

    border-radius: 5px;
    align-items: center;
  }
}

@primary-color: #FDB913;@link-color: #FDB913;