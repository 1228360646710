.integration-card-root {
  padding: 20px;
  border: 1px solid #e8e8e8;
  border-radius: 5px;

  h2 {
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 0px;
    color: #1f1f1f;
  }
  p {
    margin-top: 6px;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;

    color: #333333;

    opacity: 0.8;
  }
}

.recommended-add-btn {
  width: 100% !important;
  font-weight: 700 !important;
  font-size: 15px !important;
  line-height: 18px !important;
  border: 1px solid #fdb913 !important;
  border-radius: 5px !important;
  letter-spacing: 0.055em !important;
  height: 40px !important;
}

@primary-color: #FDB913;@link-color: #FDB913;