.c-description-root {
  border: 1px solid #e8e8e8;
  border-radius: 5px;
  padding: 25px 15px;
  height: 100%;

  h1 {
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;

    color: #333333;
  }

  p,
  textArea {
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;

    color: #333333;

    opacity: 0.8;
  }
}

.paint-order-com-dropdown {
  border: 1px solid #e8e8e8;
  border-radius: 5px;
  width: 350px;
}

.paint-order-com-desc {
  .ant-select {
    color: #333333;

    opacity: 0.8;
  }
}

.delivery-address-button {
  background-color: "#FDB913";
  color: "#1F1F1F";
  border-radius: "5px";
  font-size: "15px";

  font-weight: "bold";
  margin: "0px 10px 0px 5px";
  border: "1px solid #FDB913";
}

@primary-color: #FDB913;@link-color: #FDB913;