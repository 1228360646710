.job-card-root {
  background: #ffffff;
  box-shadow: 0px 0px 13px 2px rgba(5, 21, 63, 0.04);
  border-radius: 5px;
  // height: 400px;
  min-width: 280px;

  h1 {
    margin: 0;
    padding: 0;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;

    /* Grey 1 */

    color: #1f1f1f;
  }

  h2 {
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.055em;

    color: rgba(51, 51, 51, 0.7);
  }
  p {
    margin: 0;
    padding: 0;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    /* identical to box height, or 126% */

    /* Grey 2 */

    color: #333333;

    opacity: 0.8;
  }
}

.view-payment-card {
  background: #ffffff;
  box-shadow: 0px 0px 13px 2px rgba(5, 21, 63, 0.04);
}

.job-card-padding {
  padding: 25px 20px 5px 20px;
  margin-bottom: 20px;
}

.jc-view-payments {
  height: 40px !important;
  border-radius: 5px !important;
  font-weight: bold !important;
  font-size: 15px !important;
  line-height: 18px !important;

  letter-spacing: 0.055em !important;

  color: #1f1f1f !important;
}

.jc-tag {
  border-radius: 25px !important;
  font-weight: normal !important;
  font-size: 12px !important;
  line-height: 30px !important;
  color: #333333 !important;
  height: 32px !important;
  text-align: center;
}

.jc-menu-item {
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #333333;
  letter-spacing: 0.055em;
}

@primary-color: #FDB913;@link-color: #FDB913;