.admin-integrations-root {
  background: #ffffff;
  border-radius: 5px;
}

.integration-header {
  padding: 20px 30px;
  h1 {
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;

    color: #1f1f1f;
  }
}

.search-integrations {
  background: #ffffff !important;
  height: 40px !important;
  width: 300px !important;
  border: 1px solid #e8e8e8 !important;
  box-sizing: border-box !important;
  border-radius: 5px !important;
}

.integrations-container {
  padding: 40px 20px;

  h1 {
    font-weight: 700;
    font-size: 22px;
    line-height: 26px;

    color: #1f1f1f;
  }
}

@primary-color: #FDB913;@link-color: #FDB913;