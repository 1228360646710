.ch-root {
  padding: 40px 25px;

  p {
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.055em;
    margin-bottom: 15px;
    color: #333333;
  }

  h1 {
    font-weight: bold;
    font-size: 36px;
    line-height: 43px;

    color: #1f1f1f;
  }

  h2 {
    font-size: 16px;
    line-height: 20px;

    color: #1f1f1f;
  }
}

.contract-document-container {
  padding: 0px 25px 60px 30px;
  h1 {
    font-weight: bold;
    font-size: 22px;
    line-height: 26px;
    padding-left: 20px;

    color: #1f1f1f;
  }
  p {
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;

    color: #333333;
  }
}

.cd-root {
  padding: 37px 25px 60px 30px;
  h1 {
    font-weight: bold;
    font-size: 22px;
    line-height: 26px;

    color: #1f1f1f;
  }
  p {
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;

    color: #333333;
  }
  h2 {
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    text-align: center;

    color: #1f1f1f;
  }
}

.cd-project-card {
  background: #faf9f9;
  border-radius: 5px;
  height: 53px;
  padding: 0px 28px;
}

.cd-sub-container {
  margin-top: 60px;
  h1 {
    padding-left: 20px;
  }
}

.cd-download-doc-btn {
  margin-top: 15px;
  font-weight: bold !important;
  font-size: 15px !important;
  line-height: 18px !important;

  letter-spacing: 0.055em !important;
  padding: 0px 25px !important;
  height: 40px !important;
  border: 1px solid#FDB913 !important;
  border-radius: 5px !important;
  color: #fdb913 !important;
}

.cd-margin-btm {
  padding-bottom: 10px;
}

.contract-comp-root {
  padding: 0px 25px 0px 30px;
  h1 {
    padding-left: 20px;
    font-weight: bold;
    font-size: 22px;
    line-height: 26px;

    color: #1f1f1f;
  }
  p {
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;

    color: #333333;
  }
}

.contract-comp-root-v2 {
  padding: 0px 0px 0px 30px;
  h1 {
    // padding-left: 20px;
    font-weight: bold;
    font-size: 22px;
    line-height: 26px;

    color: #1f1f1f;
  }
  p {
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;

    color: #333333;
  }
}

.compensation-table {
  margin-top: 30px;
  .ant-table-cell {
    padding-left: 73px;
  }
}

.compensation-input {
  height: 90px !important;
  border: 1px solid #e8e8e8 !important;
  border-radius: 5px !important;
}

.equipment-root {
  padding: 60px 25px 0px 30px;
  h1 {
    font-weight: bold;
    font-size: 22px;
    line-height: 26px;

    color: #1f1f1f;
  }
  p {
    font-size: 16px;
    line-height: 20px;

    color: #333333;
  }
}

.equipment-bullets {
  p {
    margin-left: 10px;
  }
}

.e-margin-tp-20 {
  margin-top: 20px;
}

.e-margin-tp-30 {
  margin-top: 30px;
}

.correction-input {
  width: 100%;
  height: 40px !important;
  border: 1px solid #e8e8e8 !important;
  border-radius: 5px !important;
}

.contract-date-root {
  padding: 60px 25px 60px 30px;
  h1 {
    font-weight: bold;
    font-size: 22px;
    line-height: 26px;

    color: #1f1f1f;
  }
  p {
    font-size: 16px;
    line-height: 20px;

    color: #333333;
  }
}

.estimate-date-cont {
  margin-top: 25px;
  h2 {
    font-style: italic;

    font-size: 16px;
    line-height: 19px;

    color: #1f1f1f;
  }
  p {
    border-bottom: 1px solid #1f1f1f;
  }
}

.contract-footer-root {
  padding: 66px 25px 70px 30px;
  h1 {
    font-weight: bold;
    font-size: 22px;
    line-height: 26px;

    color: #1f1f1f;
  }
  p {
    font-style: italic;
    font-weight: normal;
    font-size: 13px;
    line-height: 16px;

    text-transform: capitalize;

    color: #1f1f1f;
  }
  hr {
    margin-bottom: 5px;
    border: 1px solid #1f1f1f;
    border-bottom: none;
    width: 220px;
  }
}

.cf-sign-container {
  margin-top: 50px;
}

.cf-sign-margin {
  margin-top: 33px;
}

.cf-margin-top {
  margin-top: 60px;
}

.contract-default-project-card {
  background: #faf9f9;
  border-radius: 5px;
  height: 53px;
  padding: 0px 28px;

  h2 {
    font-size: 16px;
    font-weight: bolder;
    line-height: 20px;
    color: #1f1f1f;
    padding-top: 15px;
  }
}

@primary-color: #FDB913;@link-color: #FDB913;