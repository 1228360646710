.custom-icon-radio-button {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #d9d9d9;
  height: 50px !important;
  border-radius: 4px;
  padding-top: 10px !important;
  transition: all 0.3s ease;
}

.custom-icon-radio-button:hover {
  border-color: #fdb913;
}

.custom-icon-radio-button svg {
  width: 24px;
  height: 24px;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  z-index: 1;
  color: #fdb913;
  background: #ffd15e !important;
  border-color: #fdb913;
}
.ant-radio-button-wrapper-checked.custom-icon-radio-button {
  background-color: #fdb913;
  border-color: #fdb913;
  color: white;
}

.ant-radio-button-wrapper-checked.custom-icon-radio-button svg {
  fill: white;
}

.ant-radio-button-wrapper.custom-icon-radio-button:not(
    .ant-radio-button-wrapper-checked
  ):hover {
  border-color: #fdb913;
}

//   .ant-radio-button-wrapper.custom-icon-radio-button(.ant-radio-button-wrapper-checked) {
//     background-color: #fdb913;
//     color: rgba(0, 0, 0, 0.85);
//   }

.ant-radio-button-wrapper.custom-icon-radio-button:not(
    .ant-radio-button-wrapper-checked
  ) {
  background-color: white;
  color: rgba(0, 0, 0, 0.85);
}

.ant-radio-button-wrapper-checked.custom-icon-radio-button:hover {
  background-color: #fdb913;
  border-color: #fdb913;
}

.ant-radio-button-wrapper-checked.custom-icon-radio-button:focus {
  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
}
.ant-input-group-addon {
  padding: 0px !important;
}

.sub-segment-add-button {
  color: #f7b307 !important;
  height: 30px !important;
}

.sub-segment-remove-button {
  color: red !important;
}

/* AddSegmentModal.less or your preferred CSS file */

// .ant-btn {
//   color: black !important;
// }

@primary-color: #FDB913;@link-color: #FDB913;