// @import 'antd/dist/antd.less'; // Import Ant Design styles by less entry
@import "~antd/dist/antd.less";
@primary-color: #fdb913;
@layout-header-background: white;
@layout-body-background: #e5e5e5;
@layout-sider-background: @primary-color;
// @layout-trigger-background: @layout-header-background;
// @popover-background: @layout-header-background;
@font-family: Lato;
@text-color: black;
// @component-background: #2a2f31;
// @table-header-bg: @component-background;
.ant-menu-inline {
  border-right: none;
}
.ant-layout-header {
  border-bottom: 1px solid #e6e6e6;
}
.ant-menu {
  background-color: transparent;

  .anticon svg {
    color: #1f1f1f;
  }
  .ant-menu-title-content {
    color: #1f1f1f;
  }
}
.logo {
  height: 40px;
  margin-bottom: 40px;
  text-align: center;
  img {
    transition: 0.3s;
  }
}
.ant-table table tr th {
  border-bottom-width: 2px;
}
.ant-table,
.ant-input-affix-wrapper,
.ant-input {
  background: transparent;
  color: black;
}
.ant-table-cell p {
  color: #000;
}

.sider-menu-style {
  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    font-weight: 700;
    background: #fdb913;
  }
  .ant-menu-sub.ant-menu-inline {
    background: #fdb913;
  }
}

.sider-wrapper {
  ::-webkit-scrollbar {
    display: none;
  }
}

.content--container {
  box-shadow: 0px 0px 13px 2px rgba(5, 21, 63, 0.04);
  border-radius: 5px;
  padding: 1px 0;
  border-style: none;
  background-color: #ffffff;
  margin: 20px;
  .site-layout-background {
    padding: 24px 0;
    .ant-table table tr th {
      border-bottom: transparent;
    }
  }
}

.ant-pagination-prev button,
.ant-pagination-next button,
.ant-pagination-item a {
  color: #000;
}
.ant-pagination-prev .ant-pagination-item-link,
.ant-pagination-next .ant-pagination-item-link,
.ant-pagination-item {
  background: transparent;
}

.status-wrap {
  svg {
    width: 6em;
    height: 4em;
  }
  .icon-img-pink image {
    filter: invert(35%) sepia(26%) saturate(2928%) hue-rotate(256deg)
      brightness(101%) contrast(89%);
  }
  .icon-img-light-blue image {
    filter: invert(44%) sepia(97%) saturate(264%) hue-rotate(169deg)
      brightness(91%) contrast(94%);
  }
  .icon-img-green-yellow image {
    filter: invert(86%) sepia(74%) saturate(430%) hue-rotate(6deg)
      brightness(86%) contrast(80%);
  }
  .icon-img-red image {
    filter: invert(57%) sepia(18%) saturate(1831%) hue-rotate(314deg)
      brightness(77%) contrast(101%);
  }
}

// .table_row {
//   border-width: 2;
//   cursor: pointer;
// }

// .table_container {
//   border-style: "none";
//   background-color: "#FFFFFF";
//   margin: "15px";
// }

.ant-input-prefix {
  font-size: 18px;
  padding-right: 5px;
  color: #333333;
}
.ant-table-thead > tr > th {
  background: #fff8e7 !important ;
}

// .ant-table {
//   border-collapse: collapse !important;
//   border: none !important;
// }

.ant-table-column-title {
  font-size: 16px;
  font-weight: 600;
}
.ant-table-tbody > tr > td {
  border-bottom: none;
}

.action-button {
  cursor: pointer;
  font-weight: bold;
  font-size: 25px;
  border: none;
  background: none;
}
.ant-table-thead
  > tr
  > th:not(:last-child):not(.ant-table-selection-column):not(
    .ant-table-row-expand-icon-cell
  ):not([colspan])::before {
  width: 0 !important;
}
.ant-dropdown-menu-item:hover {
  background-color: #fedc89 !important;
}

.ant-dropdown {
  width: 224px;
  height: 114px;
}

.ant-table-cell:hover button.action {
  background-color: #fafafa !important;
}

.ant-btn {
  border: "none" !important;
  font-size: "20px" !important ;
  font-weight: bold;
}

.main--steps--holder {
  max-width: 1045px;
  position: relative;
  margin: 15px auto;
  &::after {
    position: absolute;
    left: 11%;
    right: 0;
    width: 81%;
    clear: both;
    display: none;
    content: "";
    top: 15px;
    height: 1px;
    background-color: #969696;
  }

  .ant-steps-item {
    overflow: visible;
  }

  .ant-steps-item:last-child {
    flex: 1 1;
  }

  .anticon {
    color: #ffffff;
  }

  .ant-steps-item-finish .ant-steps-item-icon {
    background-color: #fdb913;
  }

  .ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item-tail {
    top: 14px;
    display: block;
    width: 84%;
    margin: 0 0 0 45%;
    padding: 0;
    left: 41px;
  }
  .ant-steps-item-wait
    > .ant-steps-item-container
    > .ant-steps-item-tail::after,
  .ant-steps-item-tail::after,
  .ant-steps-item-process
    > .ant-steps-item-container
    > .ant-steps-item-tail::after {
    background-color: #969696;
    width: calc(100% - 20px);
    height: 1px;
    margin-left: 12px;
  }
  /*
  @media screen and (min-width: 992px) {
    .ant-steps-item {
      // min-width: 205px;
    }
  }
  */
  .ant-steps-item-wait .ant-steps-item-icon {
    background-color: #969696;
    border-color: #969696;
    position: relative;
  }
  .ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon {
    color: #fff;
  }
  .ant-steps-item-content {
    display: block;
    margin-top: 9px;
  }
  .ant-steps-item-container {
    text-align: center;
  }
  .ant-steps-item-title {
    font-weight: bold;
    display: block;
    color: #1f1f1f;
    &:after {
      top: -15px;
      background: #969696;
      left: 24%;
      display: none;
    }
  }
  .ant-steps-item-wait
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-title {
    color: #1f1f1f;
  }
  .ant-steps-item-finish
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-title::after,
  .ant-steps-item-process
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-title::after,
  .ant-steps-item-wait
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-title::after {
    background-color: #969696;
  }
  .ant-steps-item:last-child > .ant-steps-item-container > .ant-steps-item-tail,
  .ant-steps-item:last-child
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-title::after {
    display: none;
  }
  .ant-steps-horizontal:not(.ant-steps-label-vertical) {
    .ant-step-first-item {
      .ant-steps-item-tail {
        left: 29px;
        width: 90%;
      }
    }
  }
}
.main--tabs--holder {
  background: #ffffff;
  box-shadow: 0px 0px 13px 2px rgba(5, 21, 63, 0.04);
  border-radius: 5px;
  margin-bottom: 25px;
  margin-top: 30px;
  .ant-steps {
    .ant-steps-item {
      flex: inherit;
    }
  }

  .ant-steps-item-active {
    font-weight: 800;
  }

  .ant-steps-navigation .ant-steps-item-container {
    display: inline-block;
    height: 100%;
    margin-left: -16px;
    padding-bottom: 12px;
    margin-right: 0px;
    text-align: left;
    transition: opacity 0.3s;
    padding-right: 14px;
    margin-left: 13px;
  }
  .ant-steps-horizontal:not(.ant-steps-label-vertical)
    .ant-steps-item:first-child {
    padding-left: 16px;
  }
  .ant-steps-item-icon {
    display: none;
  }
}
.ant-card {
  margin-bottom: 20px;
  background: #ffffff;
  box-shadow: 0px 0px 13px 2px rgba(5, 21, 63, 0.04);
  border-radius: 5px;
  .ant-card-body {
    padding: 20px 0;
    .form-title {
      margin-bottom: 0;
    }
  }
}
.edit--note--list {
  padding: 30px;
  padding-bottom: 0;
}
.ant-steps-item-active {
  .ant-steps-item-title {
    color: #fdb913 !important;
  }
}
.ant-steps-navigation .ant-steps-item::before {
  display: none;
}
.ant-steps-navigation .ant-steps-item.ant-steps-item-active::after {
  border: 1px solid #fdb913;
  border-bottom: none;
  border-left: none;
  top: 57%;
  left: 100%;
  width: 9px;
  height: 10px;
  margin-top: -14px;
  margin-left: -2px;
}
.steps-action {
  .ant-btn {
    border-radius: 5px !important;
    font-size: 15px !important;
    font-weight: normal !important;
    height: 58px !important;
    /* margin: 0px 10px 0px 240px !important; */
    padding: 20px 40px !important;
    width: 210px !important;
    color: rgb(253, 185, 19);
    border: 1px solid rgb(253, 185, 19);
    border-radius: 5px;
  }
}
.pjcc--box {
  background: #ffffff;
  border: 1px solid #e8e8e8;
  border-radius: 10px;
  text-align: center;
  margin-top: 40px;
  padding: 20px;
  height: 150px;
  h3 {
    font-size: 16px;
    font-weight: 700;
  }
  p {
    font-size: 16px;
    margin-bottom: 0;
  }
}
.document--holder {
  .checkbox--list {
    padding: 0 20px;
    h1 {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      color: #333333;
    }
    .ant-checkbox-group-item {
      margin-bottom: 10px;
    }
    p {
      small {
        padding-left: 10px;
        padding-right: 50px;
      }
    }
  }
}
.steps-action {
  text-align: center;
}
.table-row-light {
  background-color: #ffffff;
}
.table-row-dark {
  background-color: #faf9f9;
}
.table-row-red {
  background-color: #f4cccc;
}
.table-row-red:hover {
  background-color: #f4cccc;
}
.ant-table-thead > tr > th {
  font-size: 16px;
  font-weight: 600;
}
.toggle-menu-holder {
  border: 1px solid rgba(253, 185, 19, 0.2);
  border-radius: 50px;
  padding: 5px;
  margin-left: 10px;
  color: #d2232a;
}
.ant-menu {
  .ant-menu-item a:hover {
    color: #000;
  }
}

.ant-btn-primary:hover {
  background: #fedc89;
  border-color: #fedc89;
}

@primary-color: #FDB913;@link-color: #FDB913;