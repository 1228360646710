.add-new-modal {
  width: 450px !important;

  h1 {
    font-weight: 700;
    font-size: 22px;
    line-height: 26px;

    color: #1f1f1f;
  }

  h2 {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;

    color: #1f1f1f;
  }
}

.add-modal-form-input {
  background: #ffffff !important;

  border: 1px solid #e8e8e8 !important;
  border-radius: 5px !important;
  height: 50px !important;
}

.add-modal-cancel {
  height: 40px !important;
  font-weight: 700 !important;
  font-size: 15px !important;
  line-height: 18px !important;
  letter-spacing: 0.055em !important;
  border: none !important;
  // color: #fdb913 !important;
}

.add-modal-submit {
  font-weight: 700 !important;
  font-size: 15px !important;
  line-height: 18px !important;
  height: 40px !important;
  letter-spacing: 0.055em !important;
  // background: #fdb913 !important;
  border-radius: 5px !important;
  color: #1f1f1f !important;
}
.new-modal {
  width: 400px !important;
}

@primary-color: #FDB913;@link-color: #FDB913;