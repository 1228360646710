.aj-create-profile-btn {
  color: #1f1f1f !important;
  // background-color: #fdb913 !important;
  border-radius: 5px !important;
  font-size: 15px !important;

  font-weight: bold !important;
  width: 180px !important;
  height: 40px !important;
  margin-right: 16px;
}

.empty-screen-btn {
  width: 229px !important;
  height: 58px !important;
  border-radius: 5px !important;
  color: #1F1F1F !important;
  font-weight: bold !important;
  font-size: 15px !important;
  line-height: 18px !important;
}
@primary-color: #FDB913;@link-color: #FDB913;