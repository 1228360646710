.other-defaults-root {
  background: #ffffff;
  border-radius: 5px;
}

.other-defaults-add-paint {
  font-weight: 700 !important;
  font-size: 15px !important;
  line-height: 18px !important;
  border-radius: 5px !important;
  letter-spacing: 0.055em !important;

  height: 40px !important;
  // border: 1px solid #fdb913 !important;
  // color: #fdb913 !important;
}

.segment-modal-main {
  width: 700px !important;
  h1 {
    font-size: 18px;
    font-weight: 600;
  }
}
.segment-title {
  background: #ffffff;
  border: 1px solid #e8e8e8 !important;
  border-radius: 5px !important;
  height: 40px;
  margin-bottom: 10px !important;
}

.other-defaults-header {
  padding: 25px 30px;
  h1 {
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;

    color: #1f1f1f;
  }
}

.other-defaults-associate-container {
  padding: 25px 30px;
  margin-top: 40px;
  h1 {
    font-weight: 700;
    font-size: 22px;
    line-height: 26px;

    color: #1f1f1f;
  }
}

.od-market-segment-container {
  margin-top: 40px;
  padding: 25px 30px;
  h1 {
    font-weight: 700;
    font-size: 22px;
    line-height: 26px;

    color: #1f1f1f;
  }
}

.od-market-segment-btn {
  font-weight: 700 !important;
  font-size: 15px !important;
  line-height: 18px !important;

  letter-spacing: 0.055em !important;
  border-radius: 5px !important;
  height: 40px !important;
  // border: 1px solid #fdb913 !important;
  // color: #fdb913 !important;
}

@primary-color: #FDB913;@link-color: #FDB913;