.company-root {
  background: #ffffff;

  box-shadow: 0px 0px 13px 2px rgba(5, 21, 63, 0.04);
  border-radius: 5px;

  h1 {
    font-weight: 700;
    font-size: 36px;
    line-height: 43px;
    padding: 20px 30px;
    color: #1f1f1f;
  }
  h2 {
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    margin-bottom: 40px;
    color: #1f1f1f;
  }
  h3 {
    margin: 0px;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    padding-bottom: 2px;
    color: #1f1f1f;
  }

  .ant-upload.ant-upload-select-picture-card {
    width: 200px;
    height: 100px;
  }

  // .ant-upload-picture-card-wrapper {
  //   width: 0;
  // }
  // .ant-upload.ant-upload-select-picture-card {
  // border-radius: 52px;
  // }
}

.user-profiles-text {
  font-weight: 700;
  font-size: 36px;
  line-height: 43px;

  color: #1f1f1f;
}

.profiles-add-user {
  width: 400px !important;
  height: 58px !important;
  font-weight: 700 !important;
  font-size: 15px !important;
  line-height: 18px !important;

  letter-spacing: 0.055em !important;

  color: #1f1f1f !important;

  // background: #fdb913 !important;
  border-radius: 5px !important;
}

@media only screen and (max-width: 1200px) {
  .profiles-add-user {
    width: 250px !important;
  }
}

.company--logo .ant-avatar > img {
    object-fit: contain !important;
}
@primary-color: #FDB913;@link-color: #FDB913;