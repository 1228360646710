.jjc-root {
  background: #ffffff;

  box-shadow: 0px 0px 13px 2px rgba(5, 21, 63, 0.04);
  border-radius: 5px;
}

.contract-defaults-root {
  padding: 10px 20px 0px 25px;
  h1 {
    font-weight: bold;
    font-size: 22px;
    line-height: 26px;

    color: #1f1f1f;
  }
}

.contract-defaults-description {
  font-family: "lato";
  font-style: normal;
  color: #1f1f1f;
  white-space: pre-line;
  word-wrap: break-word;
  font-size: 15px;
  font-weight: normal;
  line-height: 19px;
  letter-spacing: 0.032em;
}

@primary-color: #FDB913;@link-color: #FDB913;