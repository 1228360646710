.pjcc-table {
  background-color: #fafafa;
  border: 3px solid#FDB913;
  padding: 1px;
}

.view-pjcc-container {
  width: 600px;
  box-shadow: 0px 0px 13px 2px rgba(5, 21, 63, 0.04);
  border-radius: 5px;
  .ant-table-body::-webkit-scrollbar {
    width: 0px;
  }
}

.pjcc-gross-margin {
  padding: 32px 32px 21px 43px;
  background: #ffffff;
  box-shadow: 0px 0px 13px 2px rgba(5, 21, 63, 0.04);
  border-radius: 5px;
}

.gm-input {
  width: 100% !important;
  border: 1px solid #e8e8e8 !important;
  border-radius: 5px !important;
  background: #ffffff !important;
  height: 50px;
}

.gm-title {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  margin-bottom: 10px;

  color: #1f1f1f;
}

.view-pjcc-text {
  font-weight: normal;
  font-size: 15px;
  line-height: 18px;

  color: #333333;
}

.pjcc-table-hr {
  .ant-table-header {
    background: #fff8e7 !important;
  }
}

@primary-color: #FDB913;@link-color: #FDB913;