.default-modal-rt {
  width: 700px !important;
}
.default-modal-add-item {
  font-weight: 700;
  font-size: 22px;
  line-height: 26px;

  color: #1f1f1f;
}

.default-material-form {
  .ant-select-single {
    &:not(.ant-select-customize-input) {
      .ant-select-selector {
        align-items: center;
        border-radius: 5px !important;
        border: 1px solid #e8e8e8 !important;
        height: 50px !important;
      }
    }
  }
  h2 {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;

    color: #1f1f1f;
  }
  h3 {
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.055em;

    color: #333333;
  }
}

.default-material-form-input {
  background: #ffffff !important;

  border: 1px solid #e8e8e8 !important;
  border-radius: 5px !important;
  height: 50px !important;
}

.default-material-form-text-area {
  background: #ffffff !important;

  border: 1px solid #e8e8e8 !important;
  border-radius: 5px !important;
}

.default-material-cancel-btn {
  font-weight: 700 !important;
  font-size: 15px !important;
  line-height: 18px !important;
  letter-spacing: 0.055em !important;
  border: none !important;
  // color: #fdb913 !important;
}

.default-material-add-btn {
  font-weight: 700 !important;
  font-size: 15px !important;
  line-height: 18px !important;
  height: 40px !important;
  letter-spacing: 0.055em !important;
  // background: #fdb913 !important;
  border-radius: 5px !important;
  color: #1f1f1f !important;
}

.default-modal-root {
  width: 600px !important;
}

@media only screen and (max-width: 1200px) {
  .default-modal-root {
    width: 500px !important;
  }
}

.default-bulk-btn-upload {
  font-weight: 700 !important;
  font-size: 15px !important;
  line-height: 18px !important;

  letter-spacing: 0.055em !important;

  color: #1f1f1f !important;
  // background: #fdb913 !important;
  height: 40px !important;
  // border: 1px solid #fdb913 !important;
  border-radius: 5px !important;
}

.bulk-default-dragger {
  p {
    padding-top: 10px;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;

    letter-spacing: 0.055em;

    color: #969696;
  }
  h3 {
    margin-top: 30px;
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;

    color: #333333;

    opacity: 0.8;
  }
}

.contractor-date-picker {
  width: 100%;
  height: 50px;
  border: 1px solid #e8e8e8 !important;
  border-radius: 5px !important;
}

@primary-color: #FDB913;@link-color: #FDB913;