.paint-surface-defaults-container {
  padding: 25px;
  h1 {
    font-weight: 700;
    font-size: 22px;
    line-height: 26px;

    color: #1f1f1f;
  }
}

.labor-rates-defaults-buttons {
  font-weight: 700 !important;
  font-size: 15px !important;
  line-height: 18px !important;
  border-radius: 5px !important;
  letter-spacing: 0.055em !important;

  height: 40px !important;
  // border: 1px solid #fdb913 !important;
  // color: #fdb913 !important;
}

@primary-color: #FDB913;@link-color: #FDB913;