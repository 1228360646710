.btn-container {
  display: flex;
  flex-direction: row;
  padding-top: 20px;
  // margin-left: 30px;
  > div {
    padding-right: 10px;
    margin-bottom: 0px;
    padding-bottom: 0px;
  }
}
.profile-content {
  h4 {
    font-size: 14px;
    font-weight: 600;
    color: #1f1f1f;
  }

  .form-subtitle {
    margin-bottom: 25px;
  }
  .project-type {
    margin-bottom: 15px;
  }
  .edit--text {
    color: #fdb913 !important;
    cursor: pointer;
  }
}

.profile-title {
  font-weight: bold;
  font-size: 36px;
  line-height: 43px;
  color: #1f1f1f;
}

.job-form {
  .ant-select-single.ant-select-lg:not(.ant-select-customize-input)
    .ant-select-selector {
    height: 50px;
    border-radius: 5px;
    border: 1px solid #e8e8e8;
    align-items: center;
  }
}

.pp-lead-date {
  border-radius: 5px;
  height: 50px;
  width: 100%;
  border: 1px solid #e8e8e8 !important;
}

.EditJobsite--state--dropdown
  .ant-select-single.ant-select-lg:not(.ant-select-customize-input):not(
    .ant-select-customize-input
  )
  .ant-select-selection-search-input {
  height: 16px !important;
  margin-top: 6.5% !important;
}

.EditJobsite--state--dropdown
  .ant-select-single.ant-select-lg:not(.ant-select-customize-input)
  .ant-select-selector::after,
.ant-select-single.ant-select-lg:not(.ant-select-customize-input)
  .ant-select-selector
  .ant-select-selection-item,
.ant-select-single.ant-select-lg:not(.ant-select-customize-input)
  .ant-select-selector
  .ant-select-selection-placeholder {
  line-height: 38px;
  padding-left: 0.5% !important;
}

@primary-color: #FDB913;@link-color: #FDB913;