.contract-description {
  font-family: "lato";
  font-style: normal;
  color: #1f1f1f;
  white-space: pre-line;
  word-wrap: break-word;
  font-size: 15px;
  font-weight: normal;
  line-height: 18px;
  padding: 10px 20px 0px 25px;
}

.default-title-input {
  background: #ffffff !important;

  border: 1px solid #e8e8e8 !important;
  border-radius: 5px !important;
  height: 32px !important;
  width: 250px !important;
}

.default-Form-input {
  background: #ffffff !important;

  border: 1px solid #e8e8e8 !important;
  border-radius: 5px !important;
  height: 32px !important;
  width: 500px !important;
}

.contract-save-cancel-button {
  width: 120px !important;
  font-weight: 700 !important;
  font-size: 15px !important;
  line-height: 18px !important;

  letter-spacing: 0.055em !important;

  height: 40px !important;

  color: #1f1f1f !important;

  border-radius: 5px !important;
}

.custom-radio-button {
  padding-top: 10px !important;
  height: 50px !important;
  box-sizing: "border-box" !important;
}

.contract-start-completion-p-tag {
  font-weight: bold;
  font-size: 18px;
}
@primary-color: #FDB913;@link-color: #FDB913;