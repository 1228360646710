.setting-root {
  background: #ffffff;

  box-shadow: 0px 0px 13px 2px rgba(5, 21, 63, 0.04);
  border-radius: 5px;

  h1 {
    font-weight: 700;
    font-size: 36px;
    line-height: 43px;
    padding: 20px 30px;
    color: #1f1f1f;
  }
  h2 {
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    margin-bottom: 40px;
    color: #1f1f1f;
  }
  h3 {
    margin: 0px;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    padding-bottom: 2px;
    color: #1f1f1f;
  }

  .ant-upload-picture-card-wrapper {
    width: 0;
  }
  .ant-upload.ant-upload-select-picture-card {
    border-radius: 52px;
  }
}

.setting-profile {
  margin: 30px;
}

.setting-input {
  background: #ffffff !important;
  height: 50px;
  border: 1px solid #e8e8e8 !important;
  border-radius: 5px !important;
}

.setting-save-btn {
  // background: #fdb913 !important;
  border-radius: 5px !important;
  font-weight: 700 !important;
  font-size: 15px !important;
  line-height: 18px !important;

  height: 40px !important;
  letter-spacing: 0.055em !important;

  color: #1f1f1f !important;
}

.setting-cancel-btn {
  border-radius: 5px !important;
  width: 94px !important;
  height: 40px !important;
  // border: 1px solid #fdb913 !important;
  // color: #fdb913 !important;
  font-weight: 700 !important;
  font-size: 15px !important;
  line-height: 18px !important;

  letter-spacing: 0.055em !important;
}

.setting-update-btn {
  // background: #fdb913 !important;
  border-radius: 5px !important;
  font-weight: 700 !important;
  font-size: 15px !important;
  line-height: 18px !important;

  height: 40px !important;
  letter-spacing: 0.055em !important;

  color: #1f1f1f !important;
}

@primary-color: #FDB913;@link-color: #FDB913;