.labor-container {
  background: #ffffff;
  /* shadow 1 */

  box-shadow: 0px 0px 13px 2px rgba(5, 21, 63, 0.04);
  border-radius: 5px;
}

.labor-header-title {
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
  /* identical to box height */

  /* Grey 1 */

  color: #1f1f1f;
}

.labor-header-container {
  padding: 25px 20px 22px 30px;
}

.add-surface {
  font-weight: bold;
  font-size: 15px !important;
  line-height: 18px !important;
  letter-spacing: 0.055em;
  border-radius: 5px !important;
  /* Grey 1 */
  height: 40px !important;

  color: #1f1f1f !important;
}


.add-surface:hover{
  background-color: #fdb913 !important;
}




@media screen and (min-width: 992px) {
  .add-surface {
    width: 192px;
  }
}



.labor-divider {
  border: 1px solid #e8e8e8;
  margin: 0;
}

.labor-rates-title {
  font-weight: bold;
  font-size: 22px;
  line-height: 26px;

  /* Grey 1 */

  color: #1f1f1f;
}

.labor-rates-container {
  padding-left: 20px;
  padding-bottom: 20px;
}
.pb--holder {
  cursor: pointer;
  // position: fixed;
  // bottom: 0;
  background: #fdb913;
  // right: 3%;
  // bottom: 20px;
  width: 50px;
  height: 50px;
  border-radius: 59%;
  text-align: center;
  line-height: 50px;
  font-size: 25px;
}

.ant-table-tbody > tr:hover > td {
  background-color: inherit !important;
}

/* Reset default animations/transitions */
.ant-table-tbody > tr td{
  transition: none !important;
}

.table-row-animated {
  transition: background-color 0.8s ease-in-out;
}

.move-up {
  animation: slideUp 0.6s ease-in-out;
  background-color: #fcd397; /* Add the background color for the move-up animation */
}

.move-down {
  animation: slideDown 0.6s ease-in-out;
  background-color: #fcd397; /* Add the background color for the move-down animation */
}

@keyframes slideUp {
  from {
    transform: translateY(20px);
  }
  to {
    transform: translateY(0);
  }
}

@keyframes slideDown {
  from {
    transform: translateY(-20px);
  }
  to {
    transform: translateY(0);
  }
}


@primary-color: #FDB913;@link-color: #FDB913;