.navigation-text-container {
  background-color: white;
  color: black;
  box-shadow: 0px 0px 13px 2px rgba(5, 21, 63, 0.04);
  padding: 10px 0px;
  margin-bottom: 25px;
  p {
    margin: 0;
    font-weight: bolder;
    cursor: pointer;
    display: inline-block;
  }
}

@primary-color: #FDB913;@link-color: #FDB913;