.contractor-data-default-root {
  background: #ffffff;
  padding: 20px 15px 50px 30px;
  box-shadow: 0px 0px 13px 2px rgba(5, 21, 63, 0.04);
  border-radius: 5px;
}

.contractor-data-default-header {
  h1 {
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    margin: 0px;
    color: #1f1f1f;
  }
}

.search-manufacture-input {
  width: 300px !important;
  border: 1px solid #e8e8e8 !important;
  border-radius: 5px !important;
  height: 40px !important;
}

.contractor-data-search-icon {
  color: #909497 !important;
}

.contractor-data-add-btn {
  font-weight: 700 !important;
  font-size: 15px !important;
  line-height: 18px !important;
  height: 40px !important;
  letter-spacing: 0.055em !important;
  border-radius: 5px !important;
  // border: 1px solid #fdb913 !important ;
  // color: #fdb913 !important;
}

.contractor-data-bulk-btn {
  font-weight: 700 !important;
  font-size: 15px !important;
  line-height: 18px !important;
  height: 40px !important;
  letter-spacing: 0.055em !important;
  // background: #fdb913 !important;
  border-radius: 5px !important;
  color: #1f1f1f !important;
}

.contractor-data-vertical-line {
  border-left: 1px solid #e8e8e8;
  margin: 0px 10px;
}

.cd-skills-more {
  text-decoration-line: underline;
  color: #fdb913 !important;
  cursor: pointer;
}

@primary-color: #FDB913;@link-color: #FDB913;