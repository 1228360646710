.payment-requests-main-container {
  background: #ffffff;

  box-shadow: 0px 0px 13px 2px rgba(5, 21, 63, 0.04);
  border-radius: 5px;
}

.payment-requests-sub-container {
  padding: 25px 30px;
  h1 {
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;

    color: #1f1f1f;
  }
}

@primary-color: #FDB913;@link-color: #FDB913;