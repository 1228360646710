.wo-paint-root {
  background: #ffffff;

  box-shadow: 0px 0px 13px 2px rgba(5, 21, 63, 0.04);
  border-radius: 5px;
}

.wop-title {
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
  color: #1f1f1f;
}
.wop-header {
  padding: 23px 20px 22px 30px;
}

.wo-paint-table {
  margin-top: 20px;
  margin-bottom: 60px;
  h1 {
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;

    color: #333333;
  }
  p {
    font-weight: normal;
    font-size: 15px;
    line-height: 18px;

    color: #333333;
  }
}

.place-order-hover {
  .ant-table-tbody > tr.ant-table-row:hover > td {
    background: none;
  }
}

.wo-paint-prev {
  font-weight: bold !important;
  font-size: 15px !important;
  line-height: 18px !important;
  width: 134px !important;
  letter-spacing: 0.055em !important;
  height: 40px !important;
  color: #fdb913 !important;
  border: 1px solid #fdb913 !important;
  border-radius: 5px !important;
}

.wo-paint-jump {
  font-weight: bold !important;
  font-size: 15px !important;
  line-height: 18px !important;
  width: 225px !important;
  letter-spacing: 0.055em !important;
  height: 40px !important;
  color: #fdb913 !important;
  border: 1px solid #fdb913 !important;
  border-radius: 5px !important;
}

.wo-paint-next {
  font-weight: bold !important;
  font-size: 15px !important;
  line-height: 18px !important;
  width: 134px !important;
  letter-spacing: 0.055em !important;
  height: 40px !important;
  color: #1f1f1f !important;
  border-radius: 5px !important;
}

.next-btn-po {
  font-weight: bold !important;
  font-size: 15px !important;
  line-height: 18px !important;
  width: 134px !important;
  letter-spacing: 0.055em !important;
  height: 58px !important;
  color: #1f1f1f !important;
  border-radius: 5px !important;
}

.po-imp-text {
  padding-left: 15px;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;

  color: #d2232a;
}

.initial-product-btn {
  font-weight: bold !important;
  font-size: 15px !important;
  line-height: 18px !important;
  width: 244px !important;
  height: 58px !important;
  letter-spacing: 0.055em;
  border-radius: 5px !important;
  color: #1f1f1f !important;
}

@primary-color: #FDB913;@link-color: #FDB913;