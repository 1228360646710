.desc-card-root {
  padding: 25px 20px 26px 20px;
}

.desc-card-sub {
  border: 1px solid #e8e8e8;
  border-radius: 5px;
  padding: 20px;

  h1 {
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;

    color: #1f1f1f;
  }

  p {
    font-size: 16px;
    line-height: 20px;

    color: #333333;

    opacity: 0.8;
  }
  pre {
    font-family: "lato";
    font-size: 16px;
    line-height: 20px;

    color: #333333;

    opacity: 0.8;
  }
}

@primary-color: #FDB913;@link-color: #FDB913;