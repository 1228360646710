.source-location-root {
  padding: 30px;
  margin-top: 20px;
  background: #ffffff;
  box-shadow: 0px 0px 13px 2px rgba(5, 21, 63, 0.04);
  border-radius: 5px;

  h1 {
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 26px;

    color: #1f1f1f;
  }
  p {
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;

    color: #333333;
  }
}

.source-location-add-btn {
  font-weight: 700;
  font-size: 15px;
  line-height: 18px;

  letter-spacing: 0.055em;

  // color: #fdb913;
}

@primary-color: #FDB913;@link-color: #FDB913;