.media-background {
  background: #ffffff;
  box-shadow: 0px 0px 13px 2px rgba(5, 21, 63, 0.04);
  border-radius: 5px;
  padding: 30px;
  h1 {
    font-weight: 700;
    font-size: 22px;
    line-height: 26px;

    color: #1f1f1f;
  }
  p {
    margin-top: 5px;
    font-weight: 400;
    font-size: 14px;
    color: #1f1f1f;
  }
  h2 {
    font-weight: 700;
    font-size: 18px;
    line-height: 26px;
    margin-top: 20px;
    color: #1f1f1f;
  }
}

.media-search {
  background: #ffffff !important;
  width: 300px !important;
  height: 40px !important;
  border: 1px solid #e8e8e8 !important;
  border-radius: 5px !important;
}

@primary-color: #FDB913;@link-color: #FDB913;