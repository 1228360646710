.so-root {
  padding: 33px 25px 40px 25px;
}

.so-toggle-btn {
  border-radius: 50px !important;
  margin-right: 15px !important;
}

.so-title {
  font-weight: bold;
  font-size: 22px;
  line-height: 26px;
  padding-left: 5px;

  color: #1f1f1f;
}

.so-radio-group {
  .ant-radio-button-wrapper {
    color: #969696 !important;
    border: 1px solid #d8d8d8 !important;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
  }

  .ant-radio-button-wrapper.ant-radio-button-wrapper-checked {
    color: #ffffff !important;
  }
}

.wc-root {
  padding: 40px 25px 27px 30px;

  .ant-checkbox-inner {
    border: 1px solid #969696;
  }
}

.wc-title {
  font-weight: bold;
  font-size: 22px;
  line-height: 26px;
  padding-bottom: 25px;
  color: #1f1f1f;
}

.wo-ch-text {
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  padding-left: 15px;
  color: #333333;
}

.so-select {
  width: 100%;
}

.so-date-picker {
  width: 100%;
  border: 1px solid #e8e8e8 !important;
  border-radius: 5px !important;
  height: 50px !important;
}

.so-mb-20 {
  margin-bottom: 20px;
}

.so-options-container {
  .ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector {
    height: 50px;
    border-radius: 5px;
    border: 1px solid #e8e8e8;
    align-items: center;
  }

  h1 {
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    margin-bottom: 0;
    color: #1f1f1f;
  }
}

.jj-root {
  background: #ffffff;
  margin-top: 20px;
  box-shadow: 0px 0px 13px 2px rgba(5, 21, 63, 0.04);
  border-radius: 5px;
  padding: 21px 35px;

  h1 {
    font-weight: bold;
    font-size: 22px;
    line-height: 26px;

    color: #1f1f1f;
  }

  h2 {
    font-size: 18px;
    font-weight: bold;
    line-height: 26px;

    color: #1f1f1f;
  }

  p {
    font-size: 16px;
    line-height: 20px;

    color: #1f1f1f;
  }
}

.jj-export-btn {
  margin-top: 20px;
  font-size: 15px !important;
  line-height: 18px !important;

  letter-spacing: 0.055em !important;
  width: 130px !important;
  height: 40px !important;
  // border: 1px solid #fdb913 !important;
  border-radius: 5px !important;
  color: #1f1f1f !important;
}

.jj-generate-btn {
  margin-top: 20px;
  font-weight: bold !important;
  font-size: 15px !important;
  line-height: 18px !important;

  letter-spacing: 0.055em !important;
  width: 130px !important;
  height: 40px !important;
  // border: 1px solid #fdb913 !important;
  border-radius: 5px !important;
  // color: #fdb913 !important;
}

.jj-card {
  height: 170px;
}

.cd-download-doc-btn {
  margin-top: 15px;
  font-weight: bold !important;
  font-size: 15px !important;
  line-height: 18px !important;

  letter-spacing: 0.055em !important;
  padding: 0px 25px !important;
  height: 40px !important;
  // border: 1px solid #fdb913 !important;
  border-radius: 5px !important;
  // color: #fdb913 !important;
}

.so-input {
  height: 50px;
  border-radius: 5px !important;
  border: 1px solid #e8e8e8 !important;
}

.so-input3 {
  height: 42px;
  border-radius: 5px !important;
  border: 1px solid #e8e8e8 !important;
}

.so-input2 {
  height: 40px;
  border-radius: 5px !important;
  border: 1px solid #e8e8e8 !important;
}

.compensations-input {
  border-radius: 5px !important;
  border: 1px solid #e8e8e8 !important;
}

.paymentTerms--Exception .ant-input-textarea-suffix {
  margin-right: 2% !important;
}

.ant-input-textarea-status-error.ant-input-textarea-has-feedback .ant-input,
.ant-input-textarea-status-warning.ant-input-textarea-has-feedback .ant-input,
.ant-input-textarea-status-success.ant-input-textarea-has-feedback .ant-input,
.ant-input-textarea-status-validating.ant-input-textarea-has-feedback .ant-input {
  padding-right: 7% !important;
}
@primary-color: #FDB913;@link-color: #FDB913;