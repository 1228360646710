.contract-default-root {
  background: #ffffff;

  box-shadow: 0px 0px 13px 2px rgba(5, 21, 63, 0.04);
  border-radius: 5px;
  margin-bottom: 20px;
  h1 {
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;

    color: #1f1f1f;
  }
}

.contract-add {
  width: 120px !important;
  font-weight: 700 !important;
  font-size: 15px !important;
  line-height: 18px !important;

  letter-spacing: 0.055em !important;

  height: 40px !important;

  color: #1f1f1f !important;

  border-radius: 5px !important;
}

.contract-default-add-edit-modal {
  background: #ffffff;

  box-shadow: 0px 0px 13px 2px rgba(5, 21, 63, 0.04);
  border-radius: 5px;
  h1 {
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;

    color: #1f1f1f;
  }
}

.contract-default-header {
  padding: 25px 30px;
  margin-bottom: 10px;
  background: #ffffff;

  box-shadow: 0px 0px 13px 2px rgba(5, 21, 63, 0.04);
  border-radius: 5px;

  h1 {
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;

    color: #1f1f1f;
  }
}

.pp-associates-text {
  word-wrap: break-word;
  margin: auto;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height, or 126% */

  color: #333333;
}

@primary-color: #FDB913;@link-color: #FDB913;