// Color variables (appears count calculates by raw css)
@color0: #fdb913; // Appears 2 times

.drag-text {
  color: #2c2b2b;
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  line-height: 17px;
  padding-top: 34px;
  text-align: center;
}
.or-text {
  color: #969696;
  font-size: 15px;
  font-style: normal;
  font-weight: bold;
  letter-spacing: 0.055em;
  line-height: 18px;
  opacity: 0.9;
  padding-top: 10px;
  text-align: center;
}
.browse-button {
  border-radius: 5px !important;
  // border: 1px solid @color0 !important;
  // color: @color0 !important;
  height: 40px !important;
  text-align: center;
  width: 147px !important;
}

@primary-color: #FDB913;@link-color: #FDB913;