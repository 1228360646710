.background-container {
  margin-top: 80px;
}

.login-card {
  background: #ffffff;
  box-shadow: 0px 0px 13px 2px rgba(5, 21, 63, 0.04);
  border-radius: 5px;
  width: 530px;
}

.certapro-logo {
  text-align: center;
  margin-bottom: 50px;
}

.login-title {
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
  margin-top: 40px;
  text-align: center;

  color: #333333;
}

.input-title {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #1f1f1f;
}

.input-field {
  background: #ffffff;
  border: 1px solid #e8e8e8 !important;
  border-radius: 5px !important;
  height: 50px;
}

.select-field {
  background: #ffffff;
  border: 1px solid #ececec !important;
  border-radius: 5px !important;
  // height: 50px;
}
.select-container {
  .ant-select-single.ant-select-lg:not(.ant-select-customize-input)
    .ant-select-selector {
    height: 50px;
    border-radius: 5px;
    border: 1px solid #e8e8e8;
    align-items: center;
  }
}

.forget-btn {
  font-weight: normal !important;
  font-size: 15px !important;
  line-height: 18px;
  height: 20px !important;
  letter-spacing: 0.055em;

  color: #4b94f2 !important;
}

.login-btn {
  font-style: normal;
  font-weight: bold;
  font-size: 15px !important;
  line-height: 18px;
  letter-spacing: 0.055em;
  width: 100%;
  height: 58px !important;

  color: #1f1f1f !important;
}

.input-container {
  margin-left: 65px;
  margin-right: 65px;
  .ant-select-single {
    &:not(.ant-select-customize-input) {
      .ant-select-selector {
        align-items: center;
        border-radius: 5px !important;
        border: 1px solid #e8e8e8 !important;
        height: 50px !important;
      }
    }
  }
}

.login-btn-container {
  text-align: center;
}

.enter-text {
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;

  color: rgba(51, 51, 51, 0.7);
}

@primary-color: #FDB913;@link-color: #FDB913;