.pricing-header {
  padding: 23px 0px 22px 18px;
}

.pricing-title {
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;

  color: #1f1f1f;
}

.pricing-container {
  padding-bottom: 20px;
  .ant-table-container table > thead > tr:first-child th:first-child {
    padding-left: 25px;
  }

  table tr th.ant-table-selection-column,
  table tr td.ant-table-selection-column {
    padding-left: 25px;
  }

  background: #ffffff;

  box-shadow: 0px 0px 13px 2px rgba(5, 21, 63, 0.04);
  border-radius: 5px;
}

.items-selected-container {
  padding: 25px;
}

.pricing-btn {
  font-weight: bold !important;
  font-size: 15px !important;
  line-height: 18px !important;

  letter-spacing: 0.055em;

  // color: #fdb913 !important;
  // border: 1px solid #fdb913 !important;
  border-radius: 5px !important;
  margin-right: 10px;
}

.pricing-request-btn {
  font-weight: bold !important;
  font-size: 15px !important;
  line-height: 18px !important;

  letter-spacing: 0.055em !important;

  color: #1f1f1f;
  // background: #fdb913 !important;
  border-radius: 5px !important;
  height: 40px !important;
  width: 301px !important;
  margin-top: 60px;
}

.items-selected-text {
  font-weight: normal;
  font-size: 15px;
  line-height: 18px;

  color: #000000;
}

@primary-color: #FDB913;@link-color: #FDB913;