.aj-new-profile {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    h1 {
      color: #1f1f1f;
      font-size: 24px;
      font-weight: bold;
      padding-left: 20px;
    }
  }
  
  .aj-create-profile-btn {
    color: #1f1f1f !important;
    // background-color: #fdb913 !important;
    border-radius: 5px !important;
    font-size: 15px !important;
  
    font-weight: bold !important;
    width: 180px !important;
    height: 40px !important;
    margin-right: 16px;
  }
  
  .project-menu-items {
    h1 {
      font-weight: 700;
      font-size: 15px;
      line-height: 18px;
      color: #333333;
    }
  }
  
@primary-color: #FDB913;@link-color: #FDB913;